import { ErrorBoundary } from "react-error-boundary";

import * as components from "@/components";
import ErrorMessage from "@/components/error-message";

function RenderChildren(props) {
  const { component } = props;

  let Component = components[component.contentType];

  if (typeof Component !== "function") {
    return (
      <ErrorMessage key={`${component.contentType}`}>
        <p>{component.contentType} component not found.</p>
      </ErrorMessage>
    );
  }

  return (
    <ErrorBoundary
      fallbackRender={({ error }) => (
        <ErrorMessage>
          {component.contentType} - {error.message}
        </ErrorMessage>
      )}
    >
      <Component key={`${component.contentType}`} {...component} />
    </ErrorBoundary>
  );
}

export default RenderChildren;
