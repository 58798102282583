import React from "react";
import Head from "next/head";
import useSWR from "swr";
import clsx from "clsx";
import { useRouter } from "next/router";

import isEmpty from "lodash.isempty";
import get from "lodash.get";

import { HEDGING_BASE_URL } from "@/lib/regex";

import AppLayout from "@/layouts/app-layout";
import FourOFour from "@/components/four-o-four";
import StackLayout from "@/layouts/stack-layout";
import { useWindowSize } from "react-use";

function Home() {
  const router = useRouter();
  const shouldFetch = router.isReady;

  const desktop = get(useWindowSize(), "width") > 1024 ? true : false;

  const path = shouldFetch
    ? typeof window !== "undefined" &&
      (window.location.port.includes("3000") ||
        window.location.hostname.includes("netlify"))
      ? `index.json`
      : `${HEDGING_BASE_URL}/index`
    : null;

  const { data, error, isLoading, isValidating } = useSWR(
    shouldFetch ? path : null,
    () => fetch(path).then((res) => res.json()),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    }
  );

  if (isLoading && isEmpty(data)) return <></>;

  const {
    className,
    components,
    metaData,
    url,
    contentType,
    bgDesktop,
    bgMobile,
  } = data || {};

  if (
    (data === undefined && error !== undefined) ||
    !isEmpty(data?.error) ||
    (router.asPath.includes("siteconfig") &&
      !router.asPath.includes("siteconfig.json"))
  ) {
    return <FourOFour />;
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="X-UA-Compatible" content="IE=10"></meta>

        {get(metaData, "title") ? <title>{metaData.title}</title> : null}
        {get(metaData, "description") ? (
          <meta name="description" content={metaData.description} />
        ) : null}
        {get(metaData, "keywords") ? (
          <meta name="keywords" content={metaData.keywords.toString()} />
        ) : null}

        {/* Open graph */}
        {get(metaData, "title") ? (
          <meta property="og:title" content={metaData.title} />
        ) : null}
        {get(metaData, "description") ? (
          <meta property="og:description" content={metaData.description} />
        ) : null}
        {!!url ? <meta property="og:url" content={url} /> : null}
        {get(metaData, "openGraph.image") ? (
          <meta property="og:image:url" content={metaData.openGraph.image} />
        ) : null}
        {get(metaData, "openGraph.image") ? (
          <meta property="og:type" content={metaData.openGraph.type} />
        ) : null}
        {!!contentType ? (
          <meta property="og:type" content={contentType} />
        ) : null}
        {get(metaData, "title") ? (
          <meta itemprop="name" content={metaData.title}></meta>
        ) : null}
        {get(metaData, "description") ? (
          <meta itemprop="description" content={metaData.description}></meta>
        ) : null}
        <title>{metaData?.title}</title>
      </Head>
      <AppLayout>
        <div
          style={{
            backgroundImage: desktop
              ? `url(${bgDesktop?.url})`
              : `url(${bgMobile?.url})`,
          }}
          className={clsx(
            className,
            "bg-[url('/images/bg-mobile.webp')] bg-contain bg-top bg-no-repeat ipad:bg-[url('/images/bg-desktop.webp')]"
          )}
        >
          <StackLayout components={components} />
        </div>
      </AppLayout>
    </>
  );
}

export default Home;
