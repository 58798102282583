import Head from "next/head";
import useSWR from "swr";

import get from "lodash.get";
import isEmpty from "lodash.isempty";

import { HEDGING_BASE_URL } from "@/lib/regex";
import { HedgingProvider } from "@/context/betaContext";

import { SiteFooter, ErrorMessage, SiteHeader } from "@/components/*";

function AppLayout(props) {
  const { children } = props;

  const endpoint =
    typeof window !== "undefined" &&
    (window.location.port.includes("3000") ||
      window.location.hostname.includes("netlify"))
      ? `/siteconfig.json`
      : `${HEDGING_BASE_URL}/siteconfig`;

  const {
    data: siteData,
    error: siteError,
    isLoading: siteLoading,
    isValidating,
  } = useSWR(endpoint, () => fetch(endpoint).then((res) => res.json()), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    refreshInterval: 0,
    revalidate: false,
  });

  if (siteLoading && isEmpty(siteData)) return <></>;

  if (!isEmpty(siteError)) {
    return (
      <div className="container grid h-screen place-items-center">
        <ErrorMessage title="Something went wrong">
          {siteError.message}
        </ErrorMessage>
      </div>
    );
  }

  let headerProps = get(siteData, "siteHeader");
  let footerProps = get(siteData, "siteFooter");
  let favicon = get(siteData, "favicon");

  return (
    <>
      {get(favicon, "url") ? (
        <Head>
          <link rel="icon" href={favicon.url} type="image/x-icon" sizes="any" />
        </Head>
      ) : null}

      <HedgingProvider>
        {!isEmpty(headerProps) ? <SiteHeader {...headerProps} /> : null}
        {children}
        {!isEmpty(footerProps) ? <SiteFooter {...footerProps} /> : null}
      </HedgingProvider>
    </>
  );
}

export default AppLayout;
