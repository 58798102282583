import { Fragment } from "react";
import map from "lodash.map";

import RenderChildren from "./render-children";

function StackLayout(props) {
  const { components } = props;

  return (
    <>
      {map(components, (component, i) => {
        return (
          <RenderChildren
            key={`${component?.contentType}-${i}`}
            component={component}
          />
        );
      })}
    </>
  );
}

export default StackLayout;
