import config from "@/config";

function ErrorMessage(props) {
  const {
    title = config.errorComponentTitle || "A component error has occurred",
    children,
  } = props;

  return (
    (title || children) && (
      <div role="alert" className="border-2 border-solid border-grey-400">
        {title && (
          <div className="bg-dark px-24 py-16 text-white">⚠️ {title}</div>
        )}
        {children && <div className="px-24 py-16">{children}</div>}
      </div>
    )
  );
}

export default ErrorMessage;
